.profile-wrapper {
    width: 40%;
    display: block;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

.profile-wrapper .card {
    margin-top: 1em;
}