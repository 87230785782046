html, body, #root{
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.centered-card {
    width: 50vh;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}

.fullscreen-with-color {
    position: relative;
    max-width: 100%;
    height: 100vh;
    background-color: lightblue;
    /*background: url(/img/car.jpg) no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 0;
}