.main-section {
    min-height: 100vh;
    text-align: center;
    overflow: hidden;
}

.main-content {
    background: url(/img/snow.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 20vh;
}

h6 {
    padding: 0.5rem;
}

.main-features {
    padding-top: 2vh;
}

.why-content {
    background: url(/img/australia.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 10vh;
}

.photo > img {
    border-radius: 150px;
    border: 1px solid lightblue;
    width: 80%;
    height: 80%;
    display: block;
    margin: auto;
}

.why-text {
    margin: 0 auto;
    width: 300px;
    background-color: white;
    border: 1px solid lightblue;
}

.box {
    padding: 1em;
    margin: 1em;
    border: 1px solid lightblue;
    min-height: 30vh;
}

.main-navbar-link {
    padding-right: 1em;
    color: white !important;
}

.navbar-link-active {
    font-weight: bold;
    text-transform: uppercase;
}


.links {
    padding-top: 10px;
    display: block;
    margin: auto;
    width: 80%;
    text-align: center;
}

.links > a {
    color: white;
    padding: 10px;
}