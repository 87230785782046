.point-popup {
    min-width: 30vw;
}

#map-wrapper{
    height: 100vh;
}

#point-container, #map {
    padding-top: 60px;
    height: 93vh;
}