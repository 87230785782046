.about-wrapper {
    padding-top: 100px;
}

.about-wrapper .links > a {
    color: #2196f3 !important;
}

.photo-wrapper{
    margin: 0 auto;
}