.point-list {
    background-color: white;
    padding: 20px;
    height: 500px;
    width: 250px;
    overflow: auto;
}

.point-list-map-control {
    height: 450px;
}

.point-list > .md-form {
    margin: 0 auto;
}

.point-div-link {
    border: 1px solid lightgrey;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    cursor: pointer;
}

.point-list-toggle {
    display: none;
}

.point-list-toggle-icon {
    padding: 13px;
    background-color: white;
    color: grey;
}

.point-list-toggle > i {
    background-color: white;
}

@media only screen and (min-height: 600px) and (min-width: 600px) {
    .point-list-toggle {
        display: block;
    }
}