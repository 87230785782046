.feedback-wrapper {
    width: 40%;
    display: block;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

.feedback-txt{
    padding-top: 1em;
}