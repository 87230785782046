#notfound-wrapper {
    height: 100vh;
    background: url(/img/compass.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#notfound-wrapper > h1{
    padding-top: 60vh;
    text-align: center;
    color: white;
}