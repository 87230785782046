.nav-content {
    color: white;
}

.nav-item {
    margin-left: 1rem;
}

.nav-item > i {
    cursor: pointer;
}